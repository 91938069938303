@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
* {
  font-family: "Poppins", serif !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.dashboard .MuiBackdrop-root {
  background-color: rgba(255,255,255, 0.5) !important;
  backdrop-filter: blur(10px)

  


}

.table1{
  background: linear-gradient(119deg, rgba(1, 177, 234, 0.17) 0%, rgba(232, 212, 255, 0.47) 100%);

}
.leneargradient{
  background:  linear-gradient( #CB3A73, #E74A9C);
}
.button1{
  background: linear-gradient(135deg, #9C06AA 0%, #C6376F 100%);
  

}
.asset{
  background: linear-gradient(269deg, #9B04AD -18.56%, #D1475B 132.9%);
}
.sideBar{
   background: linear-gradient(180deg, #9903AE 0%, #CC3D66 100%);
};
.leneargradient {
  background: linear-gradient(#9C06AA,#CB3C68);
}
.dailoog{
  background: linear-gradient( #8200BC, #B01991);
}
.performance1{
  background: linear-gradient(180deg, #E0C6FD 0%, rgba(224, 198, 253, 0.00) 100%);
} 
.asset1{
  background:  linear-gradient(93deg, #9D07A9 2.15%, #C93A6B 95.17%);
  ;
}